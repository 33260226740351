.navigation-tabs {
  display: flex;
  gap: 20px;
  border-radius: 8px;
  position: relative;

  &__sidebar {
    flex: 1;
    color: white;
    position: relative;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      position: relative;
    }

    .navigation-tabs__headline {
      padding: 0 15px;
      cursor: pointer;
      font-size: 1.2rem;
      transition: background 0.3s ease-in-out;
      display: flex;
      align-items: center;
      gap: 20px;
      position: relative;

      span {
        padding: 40px 0;
        text-transform: uppercase;
      }

      &.active {
        font-weight: bold;
      }

      &:hover {

      }
    }
  }

  &__connector {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20px; // Adjust spacing of square and line
  }

  // The rotated square
  &__square {
    width: 16px;
    height: 16px;
    background: #fff;
    transform: rotate(45deg);
    transition: background 0.3s ease-in-out;
    z-index: 1;
    &.active {
      background: $color-secondary;
      opacity: 1;
    }
  }

  // The vertical line
  &__line {
    width: 4px;
    height: 100%;
    position: absolute;
    background: $color-secondary

  }

  &__content {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__text {
    background: lightgrey;
    padding: 25px;
    width: 100%;
    bottom: -30%;
    @include diagonal-cut(top-right, 45px);
    @include responsive(desktop) {
      width: 520px;
      position: absolute;
      transform: translate3d(-30%, -15%, 0);
    }
  }

  &__item {
    display: none;
    animation: fadeIn 0.5s ease-in-out;

    &.active {
      display: block;
    }

    img {
      height: auto;
    }

    p {
      font-size: 1rem;
      color: #333;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
