.background-text-image {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  &__image {
    z-index: 1;
    padding-top: 25px;
    @include responsive(desktop) {
      padding-top: 0;
    }
    figure {
      height: 100%;
      @include diagonal-cut(bottom-right, 45px);
      @include responsive(desktop) {
        clip-path: polygon(0% 15%, 36% 0%, 75% 50%, 35% 100%);
      }

    }
    img {
      height: 100%;
      object-fit: cover;
    }
  }
  &__text {
    padding-top: 75px;
    padding-bottom: 75px;
    @include responsive(desktop) {
      padding-top: 150px;
      padding-bottom: 150px;
    }
    h1, h2, h3, h4, p, span {
      @include text-invert;
    }
    z-index: 1;
  }
  &:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,.65);
    bottom: 0;
    z-index: 0;
  }
  h1,h2,h3,p,span {
    color: #fff;
  }
}