//#####Breadcrumb Navigation
.breadcrumb {
  position: relative;
  z-index: 999;
  @include responsive(landscape) {
    display: block;
  }
  display: none;
  text-transform: uppercase;
  ul {
    display: flex;
  }
  span {
    display: flex;
    align-items: center;
    padding: 0 10px;
    color: #fff;
  }
  li {
    list-style: none;
    color: $color-primary; 
    &.active {
      color: $color-primary;
      font-weight: bold;
    }
  }

}
//#####End