.jobs__teaser {
  position: relative;
  padding: 30px 20px;
  margin-top: 0px;
  .empty {
    text-align: center;
    font-weight: 700;
    max-width: 600px;
    margin: auto;
    border: solid 1px;
    padding: 15px;
    margin-bottom: 45px;
  }
}