html {
  font-size: 19px;
}

body {
  font-family: $font-stack-body;
  font-size: $font-size-base;
  line-height: 1.5;
  background-color: $color-background;
}

a {
  text-decoration: none;
}

ol {
    display: block;
    list-style-type: decimal;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 40px; 
}


h1 {
  color: $color-primary;
  font-size: 1.5rem;
  margin: 0 0 15px 0;
  hyphens: auto;
}

h2 {
  font-family: $font-headline;
  font-size: 1.8rem;
  font-weight: 600;
  @include text-gradient;
  display: inline-block;
  @include responsive(phone) {
    font-size: 2.2rem;
  }
}

h3 {
  font-weight: 400;
}

h4 {
  color: $color-primary;
  font-size: 0.9rem;
}

a {
  color: $color-primary;
  &:hover {
    color: darken($color-primary, 15%);
    text-decoration: none;
  }
}

figure {
  margin: 0;
}

img {
  vertical-align: middle;
  border-style: none;
}

.shadowed {
  -webkit-box-shadow: 10px 10px 5px -1px rgba(0,0,0,0.21);
  -moz-box-shadow: 10px 10px 5px -1px rgba(0,0,0,0.21);
  box-shadow: 10px 10px 5px -1px rgba(0,0,0,0.21);
}

.subheadline {
    color: $color-secondary;
    text-transform: uppercase;
    font-weight: bold;
    font-size: .9rem;
}



label {
  margin-bottom: 0.5rem;
}
  
  .toggle-opener {
    position: absolute;
    top: 0px;
    overflow: visible;
    width: 200px;
    transform: translateY(-35px);
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    cursor: pointer;
  }

  .bg-grey {
    background:$color-background;
  }

  .bg-primary {
    p, h1, h2, h3, div, span {
      color: #fff;
    }
  }

  .bg-gradient {
    background: $bg-gradient;
    h2, p, span, div {
      color: #fff;
      @include text-invert;
    }
  }

  .bg-dark {
    background: $color-darkblue;
    color: #fff;
    h1, h2, h3 {
      color: $color-secondary;
    }
  }
  
  
  
  //////////////////////////////////content
#main .steel-divider {
  display: flex;
  width: 85%;
  bottom: -1px;
  position: absolute;
  svg {
    fill: $color-primary;
  }
}


#main > .inside > .mod_article:first-child {
  padding-top: $gap-default;
}

#main > .inside > .mod_article:last-child {
  padding-bottom: 280px;
}
  
  
  //contact icons
  .contact-wrapper {
    .inner {
      .icons {
        margin-top: 40px;
        text-align: center;
        i {
          font-size: 2rem;
          margin-bottom: 10px;
          display: block;
        }
        a {
            color: #fff;
  
        }
      }
    }
  }
  
  
  .icon-copyright {
    margin-bottom: 2rem;
  }
  
  
  
  //Registration Bar
  .registration-bar {
      position: relative;
    background-color: $color-secondary;
    min-height: 120px;
    padding: 40px 0 40px 0;
    color: #fff;
    overflow: visible;
  }
  
  
  .agreement {
      font-size: 13px;
      text-align: justify;
  }


.service-box {
    position: relative;
    background: #000;
    margin: 20px auto;
    max-width: 350px; 
    figure {
        &:before {
            z-index: 1;
            opacity: 0.8;
            content: ""; 
            height: 70px;
            width: 100%;
            background: $color-primary;
            position: absolute;
            bottom: 0;
            transition-duration: .3s;
        } 
        h3 {
            font-size: 0.9rem;
            z-index: 2;
            margin: 0 0 0.5rem 0;
            position: absolute;
            font-weight: 600;
            padding: 1rem;
            bottom: 0;
            color: #fff;
            text-transform: uppercase;
            transition-duration: .3s;
        }
        img {
            transition-duration: .3s;
            opacity: 1;  
        }
    }
}




.service__listing {
  margin-top: 25px;
  li {
    padding: 20px;
    border-bottom: solid 1px $color-primary;
    &:before {
      background-image: url("/files/input/icons/mr-list-icon.png");
      background-repeat: no-repeat;
      display: inline-block;
      width: 15px;
      height: 15px;
      content:"";
      margin-right: 10px;
    }
  }
}




.service__intro {
  font-size: 1.2rem;
}

.service-box:hover figure:before, 
.service-box:hover figure h3 {
    transition-duration: .3s;
    transform: translateY(0);
    opacity: 1;
}

.service-box:hover figure:before {
  height: 20px;
}


.service-box:hover img {
    transition-duration: .3s;
    opacity: 0.4; 
}

//KPI Leiste
.kpi-bar {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  &__kpi {
    color: $color-dark;
    text-align: center;
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
    padding: 15px;
    svg {
      height: 65px;
    }
  }
}

.kpi__icon {
  border-bottom: solid 2px $color-dark;
}

.kpi__headline {
  font-size: 2.2rem;
  font-weight: 700;
  display: block;
}

.mod_newsreader {
  .news-full {
    &__layout {
      text-align: center;
      margin: 75px auto;
    }
  }
  .content-text {
    max-width: 650px;
    margin: auto;
  }
  figure {
    height: 400px;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
}

//==Service Boxen End
.news-wrapper {
  margin-top: 50px;
  margin-bottom: 50px;
  .btn-primary {
    padding: 8px;
    text-transform: none
  }
  .layout_latest {
    .inner {
      position: relative;
      background: #fff;
      border: solid 1px lightgrey;

      .description {
        padding: 25px;
        z-index: 1;
        position: relative;
      }
      svg {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
        opacity: 0.2;
      }
    }
  }
}


.layout_latest.first {
  display: flex;
}


//Info-Box 
.info-box {
  display: flex;
  color: $color-dark;
  margin: 40px 0;
  font-size: 0.9rem;
  p {
    padding: 25px;
    display: flex; 
    margin-bottom: 0;
    align-self: center;
  }
  svg {
    height: 40px;
    width: 40px;
  }
  .exclamation-circle {
    padding: 25px;
    color: #fff;
    background-color: $color-primary;
    display: flex;
    align-items: center;
  }
}

//Openstreetmap
.c4g-popup-wrapper {
  border-radius: 0 !important;
  padding: 25px !important;
}

//####Download Listing
.ce_downloads {
  font-size: 1.2rem;
  li {
    padding: 20px;
    border-bottom: solid 1px $color-primary;
    background-color: lighten($color-dark, 65);
    margin-bottom: 30px;
  }
  a {
    display: flex;
    justify-content: space-between;
  }
}

//####End



@media screen and (min-width: 768px) {
  .service-box {
    max-width: 100%;
  }
  h1 {
    font-size: 2.5rem;
  }
  .layout_latest {
    display: flex;
  }
}

@media screen and (min-width: 992px) {
  .service-box figure h3 {
    font-size: 1.2rem;
  }
}


//Breakout | Text / Image

.breakout-container {
  @include responsive(landscape) {
    flex-direction: row;
  }
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__image-left {
    @include responsive(landscape) {
      width: 50%;
    }
    display: flex;
    align-self: stretch;
    figure {
      width: 100%;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &__image-right {
    @include responsive(landscape) {
      width: 50%;
    }
    display: flex;
    align-self: stretch;
    figure {
      width: 100%;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &__text-left {
    @include responsive(landscape) {
      padding-left: calc(50vw - 560px);
      padding-right: 100px;
      width: 50%;
    }
    padding: 25px 15px;
  }
  &__text-right {
    @include responsive(landscape) {
      padding-right: calc(50vw - 560px);
      padding-left: 100px;
      width: 50%;
    }
    padding: 25px 15px;
  }
}

.headline__highlight {
  color: $color-primary;
}

.sidebar {
  display: none;
  @include responsive (landscape) {
    display: block;
  }
}

.community__subheadline {background-color: #e3efd8; border-radius: 25px; font-size: 0.9rem; padding: 5px 15px;margin-top: 20px; display: inline-flex;}
.community__card {display: flex; flex-direction: column; justify-content: space-between; color: $color-dark; background: #fff; height: 100%; padding: 45px}
.community__headline {font-size: 1.2rem}
.community__teaser {font-size: 0.9rem; margin-top: 25px}
.community__teaser ul li:nth-child(n+4) {display: none}
.community__teaser ul li {border-bottom: solid 1px; padding: 10px 0}

.community__teaser ul li:first-child {border-top: solid 1px; padding: 10px 0;}


.image {
  &__background {
    position: absolute;
    z-index: -1;
  }
  &__shape-bottom-left {
    @include diagonal-cut(bottom-left, 10%)
  }
  &__shape-bottom-right {
    @include diagonal-cut(bottom-right, 10%)
  }
  &__shape-top-right {
    @include diagonal-cut(top-right, 10%)
  }
  &__shape-top-left {
    @include diagonal-cut(top-left, 10%)
  }
}

.services__box {
  background: #fff;
  padding: 25px;
  height: auto;
  box-sizing: border-box;
  @include diagonal-cut(bottom-right, 45px);



    figure {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 65px;
      height: 65px;
      &:before {
        content: "";
        width: 65px;
        height: 65px;
        border-radius: 50%;
        top: 0;
        left: 0;
        position: absolute;
        background: #DEE5EA;
      }
    }
    img {
      position: relative;
      width: 40px;
      height: auto;
      z-index: 1;
      fill: $color-primary;
    }
  p{
    font-size: .9rem;
  }
  h3 {
    margin-top: 35px;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold
  }

  }

