.badge {
  width: 120px;
  position: absolute;
  top: -45px;
  overflow: visible;
  z-index: 99;
  //opacity: 0;
  @include responsive(tablet) {
    width: 180px;
  }
  svg {
    width: 100%;
    display: block;
    height: auto;
    overflow: visible;
  }
}


.badge__outer{
  animation: rotate infinite 8s linear;
  transform-origin: center center;
}