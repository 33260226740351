.header__start {
    img {
        margin: auto;
    }
    .mod_pageimage figure {
        position: relative;
        &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            z-index: 1;
            height: 100%;
            width: 100%;
            background: #000;
            opacity: .3;
        }
    }
    h1 {
        font-size: 2rem;
        line-height: 1.3;
        margin: 0;
        color: #fff;
        @include responsive(tablet) {
            font-size: 2.5rem;
        };
        @include responsive(desktop) {
            font-size: 3.5rem;
        };
        span {
            opacity: .6;
        }
    }
    &__text {
        font-size: 0.9rem;
        color: #fff;
        font-weight: 400;
        margin: 20px 0 5px 0;
        @include responsive(tablet) {
            font-size: 1.2rem;
            font-weight: 600;
            margin: 20px 0 40px 0;
        };
    }
    .content-gallery {
        position: absolute;
        left: 0;
        right: 0;
        top: 90%;
        margin: auto;
        z-index: 99;
        @include responsive(tablet) {
            top: 80%;
        }
        ul {
            display: flex;
        }
        figure {
            max-width: 100px;
            margin-right: 25px;
            @include responsive(tablet) {
                max-width: 170px;
            }
        }
    }
}
.header__start {
    &__headline {
        position: absolute;
        z-index: 2;
        top: 55%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        font-family: $font-headline;
        span {
            color: #fff;
        }
    }
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        z-index: 1;
        height: 25%;
        width: 100%;
        opacity: .6;
        background: linear-gradient(180deg, #022236 0%, rgba(#022236,0) 100%);
    }
    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        z-index: 1;
        height: 80%;
        width: 100%;
        background: linear-gradient(0deg, $color-primary 0%, rgba($color-primary,0) 100%);
    }
}


.header__headline {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 9;
    h1 {
        color: #fff;
        text-align: center;
    }
}

.hero__subpage {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        z-index: 1;
        height: 25%;
        width: 100%;
        opacity: .6;
        background: linear-gradient(180deg, #022236 0%, rgba(#022236,0) 100%);
    }
    .image_container {
        &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 60%;
            background: linear-gradient(0deg, #003A5D 0%, rgba(73,73,73,0) 100%);
        }
    }
    .header__headline {
        top: 65%;
        transform: translateY(-50%);
        h1 {
            text-align: left;
        }
    }
}

.hero__subpage figure, .hero-start figure {
    text-align: center;
    img {
        width: 100%;
    }
}

.quicknavigation {
    position: fixed;
    right: 0;
    top: 50%;
    background: $color-primary;
    z-index: 99;
    ul {
        display: flex;
        flex-direction: column;
        margin: 0;
    }
    li {
        display: flex;
    }
    a {
        padding: 15px;
    }
    i {
        color: #fff;
    }
}


 
@media screen and (min-width: 576px) {
    .hero-start {
        .hero-image {
            display: block;
        }
        h1 {
            font-size: 3rem;
        }
        p {
            font-size: 1.2rem;
        }
    }
}


//###Pageimage######################
.mod_pageimage {
    .slider-control {
        position: absolute;
        left: 0;
        bottom: 40px;
        width: 100%;
        z-index: 2;

        > a {
            display: none;
            color: transparent;
            font-size: 0;
        }

        > .slider-menu {
            position: absolute;
            left: 50%;
            bottom: 0;
            font-size: 0;

            b {
                position: relative;
                font-size: 0;
                display: inline-block;
                width: 30px;
                height: 30px;
                margin: 0 6px;
                border: 4px solid #ffffff;
                border-radius: 50%;
                color: transparent;

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    background-color: #fff;
                }

                &:not(:hover):not(.active):before {
                    background-color: transparent;
                }

                &.active:before {
                    opacity: .3;
                }
            }
        }
    }
}


#header {
    .steel-divider {
        position: absolute;
        bottom: -1px;
        left:0;
        display: flex;
        max-width: 65%;
        width: 100%;
        z-index: 50;
        svg {
           fill: $color-background;
        }
    }
}
