.news-bar {
	display: none;
	background: $color-primary;
	color: #fff;
	padding: 10px 0;
	font-size: 0.8rem;
	text-align: center;
	p {margin: 0}
	a {color: #fff}
}

.info-bar {
	//Info-Bar
	background: #f3f3f3;
	display: flex;
	.container {
		display: flex;
		justify-content: end;
		.items-left, .items-right {
			font-size: 0.8rem;
			a {
				color: #808080;
				&:hover {
					color:$color-primary;
				}
			}
			
			.phone {
				float: right;
				padding: 10px;
				display: flex;
			}
			.envelope {
				float: right;
				padding: 10px;
				display: flex;
			}

			.facebook {
				font-size: 0.8rem;
				float: right;
				padding: 10px;
				display: flex;
			}

			.login {
				float: right;
				padding: 10px 0;
				display: flex;
					&:before {
					font-family:"fontawesome";
					content:"\f090";
					font-size: 1.2rem;
					margin-right: 6px;
					line-height: 1.5rem;  
				}
			}
		}
	}
}

.envelope, .phone {
  span {
    display: none;
  }
}

@media only screen and (min-width: 576px) {
  .envelope, .phone {
    span {
      display: inline;
    }
  }
  .news-bar {display: block}
}

.info-bar-opt {
  background-color: $color-primary;
  p {
    color: #fff;
    margin-bottom: 0;
    padding: 10px;
    font-size: 0.8rem;
  }
}
