.section__quotes {
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  &:after {
    content: "";
    background: linear-gradient(90deg, rgba(110, 110,110,1) 0%, rgba(110,110,110,0) 100%);
    display: block;
    max-width: 60%;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
  &__quote {
    color: #fff;
    padding: 120px 0;
    position: relative;
    z-index: 1;
    text-align: center;
    font-weight: $bold;
    @include responsive(tablet) {
      max-width: 50%;
    }
  }
  &__text {
    font-size: 1.5rem;
    margin-bottom: 25px;
  }
  &__author {
    margin-top: 20px;
    font-weight: $bold;
  }
}