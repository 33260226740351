.btn {
  padding: 0.5rem 1rem;
  margin-top: 25px;
  display: inline-flex;
  align-content: center
}

  .btn-primary {
    color: #fff;
    background: $bg-gradient-multi;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    &:after {
      content: "";
      background: get-icon('arrow-right', #fff) no-repeat;
      background-size: 100%;
      margin-left: 8px;
      height: 30px;
      width: 30px;
      display: inline-block;
    }
    &:hover {
      background: darken($color-primary, 10%);
      color: #fff;
    }
    &--invert {
      border: solid 1px #fff;
      color: #fff;
      background: rgba(255,255,255, .4);
      &:after {
        content: "";
        background: get-icon('arrow-right', #fff) no-repeat;
        background-size: 100%;
        margin-left: 8px;
        height: 30px;
        width: 30px;
        display: inline-block;
      }
      &:hover {
        background: rgba(255,255,255, .4);
        color: #fff;
      }
    }
  }


.btn-submit{
  color: #fff;
  background: $bg-gradient-multi;
  border: none;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  &:after {
    content: "";
    background: get-icon('paper-plane', #fff) no-repeat;
    background-size: 100%;
    margin-left: 8px;
    height: 20px;
    width: 20px;
    display: inline-block;
  }
  &:hover {
    background: darken($color-primary, 10%);
    color: #fff;
  }
}

  .btn-secondary {
    &--invert {

    }
  }


