.cookiebar {
    background: rgba(#222020, 0.9);
    font-size: .9rem;
    text-align: left;
    max-width: 350px;
    height: 260px; 
    line-height: normal;
    bottom: 20px;
    left: auto;
    right: 20px;
    padding: 20px;
    justify-content: left;
    .cookiebar__button {
      background: $color-primary;
      border: none;
      margin-top: 10px; 
      color: #fff;
      display: block;
    }
    a {
      display: inline;
      &:hover {
        text-decoration: underline;
    }
  } 
  }
  
  .cookiebar__message, .cookiebar__text {
    margin-bottom: 20px; 
  }


  /*Toggle Switch */
/* The switch - the box around the slider */
.switch {
	position: relative;
	display: inline-block;
	width: 50px;
    height: 20px;
    margin-right: 10px
}

/* Hide default HTML checkbox */
.switch input {
			opacity: 0;
		}
			


/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 15px;
	width: 15px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

input:checked + .slider {
	background-color: $color-primary;
}

input:focus + .slider {
	box-shadow: 0 0 1px $color-primary;
}

input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    height: 23px;
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}