/*========================Variables======================*/
$font-headline: 'Space Grotesk', sans-serif;
$font-stack-body: 'Space Grotesk', sans-serif;

$font-size-base: 1rem;
$font-size-imp: 1.25rem;
$font-size-small: 0.9rem;

$container-width: 1300px;

$bold: 600;
$normal: 500;
$light: 400;
$grey: #ebeef2;
$brown: #EDE9E2;
$color-background: #F2F5FB;

//Variables

$color-primary: #003A5D;
$color-secondary: #03928f;
$color-darkblue: #002B38;

$color-header: #fff;
$color-dark: #494949;

$gap-default: 150px;
$gap-default-mobile: calc($gap-default / 2);

$shadowed: 1px 2px 14px 2px rgba(0,0,0,.22);

$clip-path: polygon(85% 0,100% 25%,100% 100%,0 100%,0 0);
$bg-gradient-multi: linear-gradient(120deg, $color-primary, $color-secondary);
$bg-gradient: linear-gradient(180deg, $color-primary 0%, darken($color-primary, 14%) 100%);

@mixin text-gradient {
  background: $bg-gradient-multi;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin text-invert {
  background: none;
  color: #fff;
  -webkit-text-fill-color: unset;
}


@mixin button-primary ($color, $background)  {
  color: $color;
  background-color: $background;
  padding: 0.65rem 0;
  width: auto;
  font-size: 0.9rem;
  font-weight: $normal;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  text-transform: uppercase;
  &:hover {
    color: $color;
  }
}


@mixin diagonal-cut($corner, $size) {
  @if $corner == top-left {
    clip-path: polygon(#{$size} 0, 100% 0, 100% 100%, 0 100%, 0 #{$size});
  } @else if $corner == top-right {
    clip-path: polygon(0 0, calc(100% - #{$size}) 0, 100% #{$size}, 100% 100%, 0 100%);
  } @else if $corner == bottom-right {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - #{$size}), calc(100% - #{$size}) 100%, 0 100%);
  } @else if $corner == bottom-left {
    clip-path: polygon(0 0, 100% 0, 100% 100%, #{$size} 100%, 0 calc(100% - #{$size}));
  } @else {
    @warn "Invalid corner name. Use: top-left, top-right, bottom-right, or bottom-left.";
  }
}







@mixin glassmorphism {
  background: rgba( $color-primary, 0.25 );
  backdrop-filter: blur( 4.5px );
  -webkit-backdrop-filter: blur( 4.5px );
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

@mixin image-caption {
  color: $color-dark;
  font-weight: $bold;
  font-size: 0.9rem;
}

@mixin icon ($size) {
  display: inline-block;
  content: "";
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  height: $size + px;
  width: $size + px;
}


//Media Structure 2019
@mixin responsive ($breakpoint) {
    @if ($breakpoint == phone) {
      @media screen and  (min-width: 576px) { @content };
    } 
    @if ($breakpoint == tablet) {
      @media screen and  (min-width: 768px) { @content };
    } 
    @if ($breakpoint == landscape ) {
      @media screen and  (min-width: 992px) { @content };
    } 
    @if ($breakpoint == desktop) {
      @media screen and  (min-width: 1200px) { @content };
    }
    @if ($breakpoint == extralarge) {
      @media screen and  (min-width: 1600px) { @content };
    }
  }