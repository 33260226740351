.section__goodtoknow {
  background: url('/files/input/src/metallstruktur_soft.jpg');
  background-size: cover;
  min-height: 900px;
  h2 {
    @include text-invert;
  }
}

.section__contact {
  .contact__box {
    padding: 45px;
    height: 100%;
    a:not(.btn) {
      color: $color-secondary;
    }
    h3 {
      margin-top: 0;
    }
  }
}

.section__cta {
  background: url('/files/input/src/gewerksskizze.jpg');
  background-size: cover;
}

.section__services {
  background: url("/files/input/_DSC5202.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 250px;
  h1, h2, h3, p, span {
    @include text-invert;
  }
}

.section__kpi {
  position: relative;
  display: flex;
  align-items: center;
  .content-image {
    position: absolute;
    margin-bottom: 0;
    height: 100%;
    width: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit:cover;
    }
    figure {
      height: 100%;
    }
    &:after {
      content:"";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: darken($color-primary, 10%);
      opacity: .9;
    }
  }
  h2 {
    @include text-invert;
    margin-bottom: 0;
  }
  .kpi {
    &__wrapper {
      position: relative;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      z-index: 1;
    }
    &__element {
      padding: 15px;
    }
    &__value {
      font-size: 2.3rem;
      font-weight: bold;
      @include text-gradient;
    }
    &__text {
      font-size: .9rem;
    }
  }
}

.section__serviceslider {
  background: url("/files/input/src/ms_logoshape.svg");
  background-repeat: no-repeat;
  background-position: bottom -100px left 0;
  background-size: 50%;
}

.section__news {
  background: url("/files/input/src/gewerksskizze.jpg");
  background-size: cover;
  .swiper_news {
    margin-top: 25px;
  }
}

.section__faq {
  position: relative;
  overflow: visible;
  .mod_faqlist {
    :not(:nth-child(-n+3)) {
      display: none;
    }
  }
}

.section__workflow {
  background: $bg-gradient;
  h1, h2, h3, p, span {
    @include text-invert;
  }
  .text--invert {
    text-align: left;
    @include responsive(desktop) {
      text-align: right;
    }
  }
}

.section__career {
  background-image: url("/files/input/src/steel-pattern.svg");
  background-size: cover;
}