//Footer
#footer {

  color: #fff;
  text-align: center;
  @include responsive(tablet) {
    text-align: left;
  }
  .inside {
    background: $bg-gradient;
    padding-top: 65px;
  }
  .inner {
    padding-bottom: 20px;
    border-bottom: solid 1px #fff;
    h3 {
      color: $color-primary;
      margin: 50px 0 20px;
    }
    p {
      font-size: 0.9rem;
      color: #fff;
    }
    a {
      color: #fff;
      &:hover {
        color: #fff;
      }
    }
  }
  .logo {
    margin: auto;
    max-width: unset;
    @include responsive(tablet) {
      margin: 0;
    }
  }
  .steel-divider {
    margin-bottom: -1px;
    display: -ms-flexbox;
    display: flex;
    max-width: 65%;
    width: 100%;
    z-index: 99;
    fill: $color-primary;
  }
}

.footer-form { 
  input, textarea {
    border: 2px solid #fff;
    border-radius: 0px;
    margin: 5px 0;
    background: none;
    padding: 15px;
    color: #fff;
    box-shadow: none;
    &:focus {
      background: none;
      border-color: $color-primary;
    }
  }

}

.agreement label {
    display: inline;
}
//Copyright
.copyright {
  font-size: 0.8rem;
  padding-top: 20px;
  ul {
    @include responsive(phone) {
      flex-direction: row;
    }
    flex-direction: column;
  }
  a {
    color: #fff;
  }
}