.handorgel__header__button {
  background: rgba(255,255,255, .7);
  padding: 20px 35px 20px 15px;
}
.handorgel__content {
  background: #fff;
}

.handorgel__header {
  position: relative;
  font-size: 1rem;
  &:after {
    content: "";
    background: get-icon('chevron-down', $color-primary) no-repeat;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    height: 20px;
    width: 20px;
  }
}