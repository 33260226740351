.imagenavigation__links li{
  margin-bottom: 10px;
  border-bottom: 1px solid $color-primary;
  display: flex;
  justify-content: space-between;
  font-weight: $bold;
  cursor: pointer;
  padding: 10px 10px 10px 0;
  color: $color-primary;
  cursor: pointer;
}


.imagenavigation__image {
  display: none;
  &.active {
    display: block;
  }
}