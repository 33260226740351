

::selection {
  background-color: $color-primary;
  color: #fff;
}

::-moz-selection {
  background-color: $color-primary;
  color: #fff;
}

.teaser-text::-moz-selection {
  background-color: darken($color-primary, 10%);
  color: #fff;
}

/* width */
::-webkit-scrollbar {
    width: 15px;
    height: 20px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $color-primary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: darken($color-primary, 0.9);
}
