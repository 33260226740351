#overlay {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, .6);
  opacity: 0;
  z-index: 999;
  transition: all .3s ease-in-out;
  &.active {
    visibility: visible;
    transition: all .3s ease-in-out;
    display: flex;
    opacity: 1;
  }
  .inside {
    position: unset;
  }
}

.search {
  &__overlay {
    position: absolute;
    top: 50%;
    max-width: 800px;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
    background: $grey;
    padding: 45px;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .formbody {
      display: flex;
    }
    input {
      height: 100%;
    }
    
  }
}

.mod_search {
  .formbody {
    display: flex;
  }
  .info {
    padding-bottom: 75px;
    border-bottom: solid 1px #000;
  }
}

