.section__facts {
  position: relative;
  width: 100%;
  padding: 120px 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  flex-direction: column;
  @include responsive(landscape) {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    flex-wrap: wrap;
  }
  h2 {
    color: #fff;
    background: none;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
  }
  &:after {
    content: "";
    background: rgba(27, 46, 65,.8);
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
  &__element {
    position: relative;
    z-index: 1;
    margin-bottom: 25px;
  }
  &__headline {
    font-weight: $bold;
    text-transform: uppercase;
    color: #fff;
  }
  &__text {

  }
  &__number {
    font-size: 2rem;
  }
  &__factlist {
    position: relative;
    color: #fff;
    z-index: 1;
    text-align: center;
    @include responsive(landscape) {
      text-align: left;
    }
  }
}