//Mobile Navigation
.mobile-navigation {
  display: flex;
  justify-content: flex-end;
  font-size: 2.5rem;
  margin-top: 25px;
  a {
    display: flex;
  }
  svg {fill: #fff; width: 60px; height: 60px}
}

//####Mobile Menu Fix
#mmenu:not( .mm-menu ) {
  display: none;
}

.mm-menu {
  font-family: $font-headline;
  font-size: 1rem;
  .mm-panels, .mm-panel, .mm-navbar {background: $color-background}
  .mm-navbar {
    font-size: $font-size-small;
  }
  .mm-listitem, .mm-navbar {color: $color-dark; text-align: left}
  .mm-btn--next:after, .mm-btn--prev:before {border-color: $color-dark}
}

mm-listview {
  margin-top: 40px;
}

//Show specific pages ONLY in MMenu
#header .mod_megamenu .mmenu--visible {
  display: none;
}


@media screen and (min-width: 992px) {
  .mobile-navigation {
    display: none;
  }
}
@media screen and (min-width: 576px) {
  .responsive-img {
    display: none;
  }
}
