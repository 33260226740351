.card-container-flex {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.card-container {                     //nur für Ansprechpartnerseite
  .card {
    margin: 40px 0 40px 0; 
  }
}
  .card {
    border: 2px solid rgba(0,0,0,.125);
    box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.50);
    min-height: 480px;
    .card-img-top {
      height: 100%;
    }
    span {
      font-size: 0.8rem;
      display: block; 
    } 
  }

@media only screen and (max-width: 992px) {
  .card-container {
    .card {
      margin: 40px auto;
    }
  }
}
