.faq__container {
  margin-bottom: 10px;
  border-bottom: 1px solid $color-primary;
}

.faq__container p {
  margin: 0;
}

.faq__question {
  display: flex;
  justify-content: space-between;
  font-weight: $bold;
  cursor: pointer;
  padding: 10px;
  color: $color-primary;
  span {
    max-width: 95%;
    display: flex;
  }
  svg {
    max-width: 5%;
  }
}



.faq__answer {
  max-height: 0;
  padding: 0 20px;
  overflow: hidden;
  transition: all .3s ease;
}

.faq__container.active .faq__answer {
  padding: 20px 20px 25px 20px;
  max-height: fit-content;
}

.faq__container {
  .icon-plus {
    display: flex;
  }
  .icon-minus {
    display: none;
  }
}

.faq__container.active .faq__question {
  .icon-plus {
    display: none;
  }
  .icon-minus {
    display: flex;
  }
}


.section__faq .faq__container:not(:nth-child(-n+3)) {
  display: none;
}