.multi-element-block {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);
  width: 100%;
  position: relative;
  margin-top: 25px;

  .multi-element-content {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0 25px 15px 25px;
    z-index: 1;
  }

  .multi-element-item {
    position: relative;
    display: flex;
    justify-content: center;
    color: white;
    margin-bottom: 25px;

    @include responsive(phone) {
      margin-bottom: 0;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      z-index: 0;
      width: 100%;
      height: 60%;
      background: linear-gradient(0deg, #003a5d, rgba(73, 73, 73, 0));
      transition: height 0.3s ease-in-out;
    }

    img {
      height: auto;
      object-fit: cover;
    }

    .multi-element-title {
      font-size: 1.5rem;
      margin-bottom: 5px;
      font-weight: bold;
      padding-bottom: 10px;
      border-bottom: solid 1px #fff;
    }

    .multi-element-subtitle {
      font-size: 1rem;
      display: flex;
      column-gap: 20px;
      opacity: 0;
      max-height: 0;
      overflow: hidden;
      visibility: hidden;
      transition: all 0.3s ease-in-out;
    }
  }

  h1, h2, h3 {
    color: #fff;
  }

  @include responsive(landscape) {
    &.two-columns {
      grid-column-gap: 15px;
      .multi-element-item:nth-child(1) { grid-area: 1 / 1 / 7 / 4; }
      .multi-element-item:nth-child(2) { grid-area: 1 / 4 / 7 / 7; }
    }

    &.three-columns {
      .multi-element-item:nth-child(1) { grid-area: 1 / 1 / 7 / 4; }
      .multi-element-item:nth-child(2) { grid-area: 1 / 4 / 4 / 7; }
      .multi-element-item:nth-child(3) { grid-area: 4 / 4 / 7 / 7; }
    }
  }

  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;
    .multi-element-item {
      width: 100%;
      &:after {
        height: 100%;
      }
    }
  }
}

// ✅ Now Works Correctly
.multi-element-item:hover {
  .multi-element-subtitle {
    max-height: 200px; // Set a reasonable max-height
    opacity: 1;
    visibility: visible;
  }

  &:after {
    height: 100%;
  }
}
