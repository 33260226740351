.content-image {
  position: relative;
  margin: 75px 0;
  @include responsive(landscape) {
    margin: 40px 0;
  }
  figure {
    overflow: hidden;
  }
}


//### Image Clipped ###
.content-image {
  &.clipped {
    &__right {
      img {
        opacity: 0;
      }
      .badge {
        right: 0;
        @include responsive(landscape) {
          left: -45px;
        }
      }
    }
    &__left {
      img {
        opacity: 0;
      }
      .badge {
        left: 0;
        @include responsive(landscape) {
          right: -45px;
        }
    }
  }
}
}