

.swiper-wrapper {
  overflow: visible;
}

.swiper-slide {
  cursor: pointer;
}

.swiper-button-wrapper {
  width: 100%;
  height: 120px;
  position: relative;
}

.swiper-button-next, .swiper-button-prev {
  color: $color-dark;
  border: solid 2px $color-dark;
  padding: 20px;
  height: 50px;
  width: 60px;
}


.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 1.2rem;
}


.swiper-button-next {
  bottom: 25px;
  right: 0;
  top: auto;
}

.swiper-button-prev {
  left: auto;
  right: 100px;
  bottom: 25px;
  top: auto;
}


.video-slider {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 1;
    background: #000;
    opacity: .2;
  }
  &__divider {
    display: flex;
    border-bottom: 3px dotted #fff;
    width: 40px;
  }
  &__wrapper {
    height: 480px;
    overflow: hidden;
    @include responsive(phone) {
      height: 650px;
    }
  }
  &__navigation {
    display: flex;
    justify-content: left;
    align-items: center;
    max-width: 900px;
    margin: auto;
    color: #fff;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    right: 0;
    top: 10%;
    width: 100%;
    overflow-x: scroll;
    z-index: 2;
    @include responsive(phone) {
      justify-content: center;
      overflow-x: hidden;
    }
    &__element {
      cursor: pointer;
      opacity: .7;
      font-size: 1.1rem;
      transition: font-size .1s ease;
      padding: 15px 10px;
      @include responsive(phone) {
        font-size: 1.5rem;
      }
      &.active {
        font-size: 1.2rem;
        opacity: 1;
        @include responsive(phone) {
          font-size: 1.8rem;
        }
      }
    }
  }
  &__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: none;
    &.active {
      display: block;
    }
  }
  &__link {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    color: #fff;
    padding: 50px;
    height: 200px;
    width: 200px;
    border-color: #fff;
    text-align: center;
    border-radius: 50%;
    background: rgba(255, 255, 255, .5);
    z-index: 5;
    overflow: hidden;
    display: none;
    &.active {
      display: flex;
    }
    &:before {
      content: "";
      position: absolute;
      background: #fff;
      bottom: 0;
      left: 0;
      right: 0;
      top: 100%;
      z-index: -1;
      transition: all .3s ease-in;
    }
    &:hover {
      border-color: #000;
      color: #000;
    }
    &:hover:before {
      top: 0;
    }
  }
  /* width */
  ::-webkit-scrollbar {
    width: 20px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 10px;
  }
}


.swiper_testimonial {
  overflow: visible;
  margin-top: 4rem;
  mask-image: linear-gradient(
                  to right,
                  transparent 0%,
                  white 10%,
                  white 90%,
                  transparent 100%
  );
  .swiper-wrapper {
    transition-timing-function: linear;
    align-items: center;
    overflow: visible;
  }
  img {
    width: auto;
    max-height: 145px;
  }
}
