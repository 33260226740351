.download-element {
  padding: 25px 15px;
  border-bottom: solid 1px;
  &:last-child {
    border-bottom: none;
  }
  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  i {
    font-size: 1.2rem;
  }
}