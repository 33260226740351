.form {
  display: flex;
  flex-direction: column;

  input {
    margin-top: 25px;
  }

  label {display: flex}

  input:not([type='checkbox']):not([type="file"]), textarea {
    width: 100%;
    padding: 10px 15px;
    background: none;
    border: none;
    border-bottom: solid 1px $color-secondary;
    &:focus {
      border-bottom: solid 1px $color-primary;
      outline: none
    }
  }
  .widget {
    margin-top: 10px;
    position: relative;
  }
}

//Floating Labels
.form__floating {
  /* Container positioning */
  .widget {
    position: relative;
    margin-top: 20px; /* Ensure some space for the floating label */
  }

  .widget textarea {
    background: #fff;
    padding-top: 40px !important;
  }

  label {
    color: #999; /* Default color */
    font-size: 16px; /* Default font size */
  }

  /* Label styling */
  label:has(+ input:not([type=select])){
    bottom: 0;
  }


  label:has(+ textarea),
  label:has(+ input:not([type=select])) {
    position: absolute;
    left: 15px;
    transform: translateY(0);
    transition: transform 0.2s, color 0.2s, font-size 0.2s;
    pointer-events: none;
  }

  /* Input padding to ensure space for the label */
  .widget input {
    padding-top: 20px; /* Space for the label when it's floating */
    padding-left: 15px;
    padding-right: 15px;
    font-size: 16px;
  }

  /* When input is focused or contains text, move the label up */
  label:has(+ input:focus),
  label:has(+ input.active) {
    transform: translateY(-30px);
    font-size: $font-size-small; /* Reduce font size when floating */
  }
  select {
    min-height: 50px;
    padding: 15px;
  }
  textarea {
    margin-top: 40px !important;;
  }
}

option{
  box-shadow: 0 0 10px 100px #FED20F inset;
  transition: all .2s ease-in-out;
}


input, textarea {
  border: solid 0.5px #a9a9a9;
  background: #fff;
  padding: 10px;
}

input[type='search'] {
  height: 100%;
}

//Checkbox

.checkbox_container input[type=checkbox] {
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
}

.checkbox_container label {
  position: relative;
  padding-left: 30px;
  font-size: 0.7rem;
}

.checkbox_container span {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: solid 1px $color-primary;
}


.checkbox_container i {
  display: none
}

.checkbox_container input:checked ~ span i {
  display: inline-block;
  font-size: 1.1rem;
}