///////////////////////////Navigation
  .main-navigation-wrapper {
    position: absolute;
    width: 100%;
    margin: auto;
    top:0;
    background: $color-background;
    transition: background-color .3s ease-in-out;
    z-index: 999;
    .main-navigation {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: unset;
      overflow: visible;
      ul {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0;
        .level_2 {
          transition-duration: .3s;
          opacity: 0;
          display: none;
        }
        li {
          .active {
            color: $color-primary;
            display: block;
            font-weight: bold;
          }
          &.submenu:hover {
            .mega-dropdown {
              opacity: 1;
              visibility: visible;
              pointer-events: visible;
              z-index: 999;
            }
          }
          a:not(.btn), .active, > span {
            position: relative;
            padding: 0px 20px 45px 20px;
            display: flex;
            &:hover {
              text-decoration: none;
            }
          }
          a, span, strong {
            cursor: pointer;
          }
        }
      }
    }

    .main-navigation__topbar {
      font-size: .85rem;
      a, strong {
        padding: 20px !important;
        opacity: .8;
        color: $color-primary;
        &.active {

        }
      }
    }

.mega-dropdown {
  background: $color-background;
  padding: 85px 0;
  max-height: fit-content;
  transition: all .3s ease-in-out;
  border-top: solid 1px $color-primary;
  transform: translateY(-1px);
  ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 20px;
  }
  a:not(.btn) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    padding: 10px 0 !important;
    color: $color-primary !important;
    margin-bottom: 10px;
    border-bottom: 1px solid $color-primary !important;
    font-size: 1.1rem;
  }
  p {
    font-size: 1rem
  }
  h3 {
    margin-top: 0;
    font-size: 1.3rem;
    font-weight: $bold;
  }
}

    @media screen and (max-width: 991.99px) {
      .main-navigation {
        display: none;
      }
    }
}

.main-navigation-wrapper span {
  color: $color-primary;
}




//### MMenu Active #####

body:not(.megamenu--active) {
  .logo svg {
    fill: #fff;
    .cls-1, .cls-2 {
      fill: #fff;
    }
  }
  .main-navigation-wrapper {
    background: transparent;
    span, a {
      color: #fff;
    }
    .main-navigation__topbar a {
      color: #fff;
    }
    .main-navigation ul li .active {
      color: #fff;
    }
  }
}

//### Logo #####


.logo {
  padding: 15px 0;
  max-width: 250px;
}



.level_2 {
span.active {
  padding: 15px 20px;
}
}


//Menü Fix
.main-navigation {
  ul.level_1 {
a.sibling.last, li.active.last, .last {
  padding-right: 0px !important;
  }
  }
}








//#####Mega Menü
.mm_dropdown {
  ul {
    display: flex;
    flex-direction: column;
    li a {
      padding: 15px 10px;
      color: $color-dark;
    }
  }
  &__headline {
    font-size: 1.8rem;
    font-weight: 700;
    grid-column: span 2;
    grid-row: 1;
    padding: 15px 0;
  }
  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 80px repeat(2, 2fr);
    grid-column-gap: 40px;
    align-items: start;
    padding: 0;
    border-top: solid 5px $color-primary;
    height: 350px;
  }
  &__image {
    grid-column: 1 / span 2;
    grid-row: 1 / span 3;
    overflow: hidden;
    height: 100%;
    figure {
      height: 100%;
      width: 100%;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &__links-first {
    display: grid;
    align-items: center;
    grid-column: 3;
    grid-row: 2 / span 3;
    a {
      padding-left: 0px;
    }
  }
  &__links-second {
    display: grid;
    align-items: center;
    grid-column: 4;
    grid-row: 2 / span 3;
    a {
      padding-right: 0;
    }
  }
  a {
    padding: 20px 15px !important;
    font-size: 1.2rem;
  }
}

.mm_dropdown > .inner {
  padding: 0 !important;
  border: none !important;
  background: #fff !important;
  opacity: 1;
}

//####Mega Menü End




