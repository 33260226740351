.mod_newslist {
  .layout_latest {
    position: relative;
  }
  .image_container {
    @include diagonal-cut(bottom-right, 15%);
    &:before {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      background: linear-gradient(0deg, #003a5d, rgba(73, 73, 73, 0));
      height: 100%;
      width: 100%;
    }
  }
}

.news__multicolumn {
  &:nth-child(even) {
    margin-top: 80px;
  }
  &:nth-child(odd) {
    margin-top: 40px;
  }
}


.news-teaser {
  &__title {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 45px;
    text-transform: uppercase;
    h1, h2, h3 {
      margin: 0;
      font-size: 1.5rem;
    }
    a, h3, span, p, div {
      color: #fff;
    }
  }
  &__subheadline {
    opacity: .6;
    margin-bottom: 10px;
  }
  &__teasertext {
    padding: 25px 0;
  }
}